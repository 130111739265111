///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		p {
			font-family: _font(family-heading);
			font-size: 0.7em;
			font-weight: _font(weight-heading);
			letter-spacing: _font(kerning-heading);
			line-height: 2.5;
			margin-top: -1em;
			text-transform: uppercase;
		}
	}
	
	.technologies {
		width: 100%;
		min-width: 100%;
		text-align: right;
		display: inline;
		font-family: _font(family-heading);
		font-size: 0.7em;
		font-weight: _font(weight-heading);
		letter-spacing: _font(kerning-heading);
		// line-height: 2.5;
		margin-top: -1em;
		margin-left: 1em;
		// text-transform: uppercase;
	}